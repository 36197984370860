// actions.js

export const setFiles = (files) => ({
  type: 'SET_FILES',
  payload: files,
});

export const setFileStatus = (status) => ({
  type: 'SET_FILE_STATUS',
  payload: status,
});

export const enableSyncButton = () => ({
  type: 'ENABLE_SYNC_BUTTON',
});

export const disableSyncButton = () => ({
  type: 'DISABLE_SYNC_BUTTON',
});

export const setRemainingProgress = (remainingProgress) => ({
  type: 'SET_REMAINING_PROGRESS',
  payload: remainingProgress,
});
