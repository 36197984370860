// // File: Popup.js

import React from 'react';
import Modal from 'react-modal';
import './login.css';
import danger from './icons/danger.png';
import cancel from './icons/progressCancel.png'

const customStyles = {
  overlay:{
    backgroundColor: 'transparent',
  },
}

const Popup = ({ isOpen, onRequestClose, message }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    className='popbody'
    ariaHideApp={false}// This is added to prevent a11y warnings, adjust as needed
    style={customStyles}
  >
    <div className='popup'>
    <div style={{display:"flex",
         justifyContent:"space-between",
         alignItems:"center",
         color:"#FFFFFF"}}  ><img src={danger} style={{height:"35px",
         width:"35px"}}></img>
      <p>{message}</p>
      <button onClick={onRequestClose} style={{border:"none",backgroundColor:"#D92728"}}>
        <img src={cancel}></img>
        </button>
    </div>
    </div>
  </Modal>
);

export default Popup;