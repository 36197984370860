import React, { useState } from 'react';
import './SideSlidePanel.css';
import can from './icons/cancel.png';
import apply from './icons/Apply.png';
import Refresh from './icons/Refresh.png';

const SideSlidePanel = ({ isOpen, onClose,handleFilterChange }) => {
    const initialState = {
        General: false,
        Project: false,
        p12: false,
        p10: false,
        lessThan9Hours: false,
        greaterThan9Hours: false,
        casLeave: false,
        lop: false,
        wfh: false,
        sickLeave: false,
        unPaid: false,
        CompOff: false,
        restrictLeave: false,
        onTime: false,
        late: false,
    };
    
    const [checkboxes, setCheckboxes] = useState({});
    
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setCheckboxes({
            ...checkboxes,
            [name]: checked,
        });
    };
    
    const handleApplyFilters = () => {
        // Call the local callback with the selected filters
        handleFilterChange(checkboxes);
        console.log(checkboxes);
       onClose();
    };
    
    const handleReset = () => {
        setCheckboxes(initialState);
        handleFilterChange({});
        onClose();
    };

    return (
        <div className={`side-slide-panel ${isOpen ? 'open' : ''}`}>
             <div className="panel-header"> 
             <div>
                <h3>Filters <button className="custom" onClick={onClose}> <img src={can} /> </button> </h3>
                </div>
                <hr/>
                </div>
                <div className="content">
                <div className="panel-header">
                    <h4>Shift</h4>
                    </div>
                    <div className="checkbox-grid">
                    {/* <label>
                        <input type="checkbox"
                            name="General"
                            checked={checkboxes.General}
                            onChange={handleCheckboxChange}
                        />
                      General
                    </label> */}
                                            <label>
                            <input
                                type="checkbox"
                                name="10:00:00"
                                checked={checkboxes["10:00:00"]}
                                onChange={handleCheckboxChange}
                            />
                            10AM
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="12:00:00"
                                checked={checkboxes["12:00:00"]}
                                onChange={handleCheckboxChange}
                            />
                            12PM
                        </label>

                        {/* <label>
                            <input
                                type="checkbox"
                                name="p5"
                                checked={checkboxes.p5}
                                onChange={handleCheckboxChange}
                            />
                            5P
                        </label> */}
                    </div>
                    <div className="panel-header" >
                    <h4>9 Met</h4>
                    </div>
                    <div className='checkbox-grid'>
                        <label>
                            <input type="checkbox"
                                name="lessThan9Hours"
                                checked={checkboxes.lessThan9Hours}
                                onChange={handleCheckboxChange}
                            />
                            &lt;9 hours
                        </label>
                        <label>
                        <input type="checkbox"
                                name="greaterThan9Hours"
                                checked={checkboxes.greaterThan9Hours}
                                onChange={handleCheckboxChange}
                            />
                            &gt;9 hours
                        </label>
                        </div>
                        <div className="panel-header">
                        <h4>Lead</h4>
                    </div>
                    <div className="checkbox-grid">
                    
                        <label>
                            <input
                                type="checkbox"
                                name="Anandabatmanaban"
                                checked={checkboxes.Anandabatmanaban}
                                onChange={handleCheckboxChange}
                            />
                            Anandabatmanaban
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Rajesh Babu"
                                checked={checkboxes["Rajesh Babu"]}
                                onChange={handleCheckboxChange}
                            />
                            RajeshBabu 
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Priyadharshini S"
                                checked={checkboxes["Priyadharshini S"]}
                                onChange={handleCheckboxChange}
                            />
                            Priyadharshini S
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Sasikala Balasubramanian"
                                checked={checkboxes["Sasikala Balasubramanian"]}
                                onChange={handleCheckboxChange}
                            />
                            Sasikala
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="A J Anand Robertson"
                                checked={checkboxes["A J Anand Robertson"]}
                                onChange={handleCheckboxChange}
                            />
                           A J AnandRobertson
                        </label>
                        <label>
                            <input
                            type="checkbox"
                            name="Parthiban Ramasamy"
                            checked={checkboxes["Parthiban Ramasamy"]}
                            onChange={handleCheckboxChange}
                        />
                       Parthiban
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="Devipriya"
                            checked={checkboxes.Devipriya}
                            onChange={handleCheckboxChange}
                        />
                    Devipriya
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="Shiny"
                            checked={checkboxes.Shiny}
                            onChange={handleCheckboxChange}
                        />
                    Shiny
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="Vignesh BR"
                            checked={checkboxes["Vignesh BR"]}
                            onChange={handleCheckboxChange}
                        />
                    Vignesh BR
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="Balaji Samraj"
                            checked={checkboxes["Balaji Samraj"]}
                            onChange={handleCheckboxChange}
                        />
                    BalajiSamraj   
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="Mohammed Irfan"
                            checked={checkboxes["Mohammed Irfan"]}
                            onChange={handleCheckboxChange}
                        />
                    MohammedIrfan
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="Devendran Paramasivam"
                            checked={checkboxes["Devendran Paramasivam"]}
                            onChange={handleCheckboxChange}
                        />
                    Devendran
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="Sridhar Rajendran"
                            checked={checkboxes["Sridhar Rajendran"]}
                            onChange={handleCheckboxChange}
                        />
                    Sridhar
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="Ramkumar Somasundara Pandian"
                            checked={checkboxes["Ramkumar Somasundara Pandian"]}
                            onChange={handleCheckboxChange}
                        />
                    Ramkumar
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="Baskar Kothandapani"
                            checked={checkboxes["Baskar Kothandapani"]}
                            onChange={handleCheckboxChange}
                        />
                    Baskar
                    </label>

                </div>
                <div className="panel-header" >
                <h4>On Time</h4>
                </div>
                <div className='checkbox-grid'>   
                    <label>
                        <input type="checkbox"
                            name="onTime"
                            checked={checkboxes.onTime}
                            onChange={handleCheckboxChange}
                        />
                        On Time
                    </label>
                    <label>
                        <input type="checkbox"
                            name="late"
                            checked={checkboxes.late}
                            onChange={handleCheckboxChange}
                        />
                      Late
                    </label>
                    </div>
                    <div className="panel-header" >
                <h4>Project</h4>
                </div>
                <div className='checkbox-grid'>   
                    <label>
                        <input type="checkbox"
                            name="HR/TA Manager"
                            checked={checkboxes["HR/TA Manager"]}
                            onChange={handleCheckboxChange}
                        />
                        HR/TA Manager
                    </label>
                    <label>
                        <input type="checkbox"
                            name="ACRT"
                            checked={checkboxes.ACRT}
                            onChange={handleCheckboxChange}
                        />
                      ACRT
                    </label>
                    <label>
                        <input type="checkbox"
                            name="Design"
                            checked={checkboxes.Design}
                            onChange={handleCheckboxChange}
                        />
                      Design
                    </label>
                    <label>
                        <input type="checkbox"
                            name="AlertE-IAM"
                            checked={checkboxes["AlertE-IAM"]}
                            onChange={handleCheckboxChange}
                        />
                      AlertE-IAM
                    </label>
                    <label>
                        <input type="checkbox"
                            name="Whitecup"
                            checked={checkboxes.Whitecup}
                            onChange={handleCheckboxChange}
                        />
                      Whitecup
                    </label>
                    <label>
                        <input type="checkbox"
                            name="Ecsite"
                            checked={checkboxes.Ecsite}
                            onChange={handleCheckboxChange}
                        />
                      Ecsite
                    </label>
                    <label>
                        <input type="checkbox"
                            name="VRAP"
                            checked={checkboxes.VRAP}
                            onChange={handleCheckboxChange}
                        />
                      VRAP
                    </label>
                    <label>
                        <input type="checkbox"
                            name="Deoldata"
                            checked={checkboxes.Deoldata}
                            onChange={handleCheckboxChange}
                        />
                      Deoldata
                    </label>
                    <label>
                        <input type="checkbox"
                            name="AlertE-Devops"
                            checked={checkboxes["AlertE-Devops"]}
                            onChange={handleCheckboxChange}
                        />
                      AlertE-Devops
                    </label>
                    <label>
                        <input type="checkbox"
                            name="Operations"
                            checked={checkboxes.Operations}
                            onChange={handleCheckboxChange}
                        />
                      Operations
                    </label>
                    <label>
                        <input type="checkbox"
                            name="Azure Devops"
                            checked={checkboxes["Azure Devops"]}
                            onChange={handleCheckboxChange}
                        />
                      Azure Devops
                    </label>
                    <label>
                        <input type="checkbox"
                            name="Pathfactory"
                            checked={checkboxes.Pathfactory}
                            onChange={handleCheckboxChange}
                        />
                      Pathfactory
                    </label>
                    <label>
                        <input type="checkbox"
                            name="Ecsite/ Pathfactory"
                            checked={checkboxes["Ecsite/ Pathfactory"]}
                            onChange={handleCheckboxChange}
                        />
                      Ecsite/Pathfactory
                    </label>
                    <label>
                        <input type="checkbox"
                            name="TA"
                            checked={checkboxes.TA}
                            onChange={handleCheckboxChange}
                        />
                      TA
                    </label>
                    <label>
                        <input type="checkbox"
                            name="APM"
                            checked={checkboxes.APM}
                            onChange={handleCheckboxChange}
                        />
                      APM
                    </label>
                    <label>
                        <input type="checkbox"
                            name="Accounts"
                            checked={checkboxes.Accounts}
                            onChange={handleCheckboxChange}
                        />
                      Accounts
                    </label>
                    <label>
                        <input type="checkbox"
                            name="PM"
                            checked={checkboxes.PM}
                            onChange={handleCheckboxChange}
                        />
                      PM
                    </label>
                    <label>
                        <input type="checkbox"
                            name="Infra"
                            checked={checkboxes.Infra}
                            onChange={handleCheckboxChange}
                        />
                      Infra
                    </label>
                    <label>
                        <input type="checkbox"
                            name="BM-Prod"
                            checked={checkboxes["BM-Prod"]}
                            onChange={handleCheckboxChange}
                        />
                      BM-Prod
                    </label>
                    <label>
                        <input type="checkbox"
                            name="Fresher"
                            checked={checkboxes.Fresher}
                            onChange={handleCheckboxChange}
                        />
                      Fresher
                    </label>
                    </div>
                    <div className='resetApply'>
                    <button type='button' className="reset"  onClick = {handleReset}><img src={Refresh} className='icon' /></button> 
                    <button className="customs" onClick={handleApplyFilters}><img src={apply}  /></button> 
                    </div>
                </div>
                </div>
               
);
};

export default SideSlidePanel;
