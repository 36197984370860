// App.js
import React from 'react';
import './App.css';
import AppRouter from './AppRouter'; // Import the AppRouter component
import Header from './Home';
import Datepicker from './Datapicker';
import AddTable from './AddTable';
import Home from './Home';
import { Router, Switch, Route} from 'react-router-dom';
import SettingsPage from './SettingsPage';
import Login from './Login';



function App() {
  return (
    <div className="App">
        <Home />
    </div>
  );
}

export default App;
