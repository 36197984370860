import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'; // Import redux-thunk

const initialFileState = {
  files: [],
  fileStatus: [], // To track file progress status
  syncButtonEnabled: false,
};

const fileReducer = (state = initialFileState, action) => {
  switch (action.type) {
    case 'SET_FILES':
      return {
        ...state,
        files: action.payload,
      };
    case 'SET_FILE_STATUS':
      return {
        ...state,
        fileStatus: action.payload,
      };
    case 'ENABLE_SYNC_BUTTON':
      return {
        ...state,
        syncButtonEnabled: true,
      };
    case 'DISABLE_SYNC_BUTTON':
      return {
        ...state,
        syncButtonEnabled: false,
      };
      case 'SET_REMAINING_PROGRESS':
        return {
          ...state,
          remainingProgress: action.payload,
        };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  file: fileReducer,
});

// Apply redux-thunk middleware
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
