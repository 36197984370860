import './mini.css'; 
import logo from './icons/logo.png';
import Home from './icons/Home.png';
import settingsss from './icons/settingsss.png'
import logout from './icons/logout.png';
import AddTable from './AddTable';
import Datepicker from './Datapicker';
import Sbutton from './icons/whiteSButton.png';
import Hbutton from './icons/blueHButton.png';
import React, { useState } from 'react';
import SettingsPage from './SettingsPage';
import logoutb from './icons/logoutb.png'   //called
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';





function Header(props) {
  const [isSettingsHovered, setIsSettingsHovered] = useState(false);
  const [isHomeHovered, setIsHomeHovered] = useState(false);
  const [isLogoutHovered, setIsLogoutHovered] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();

  // Retrieve the username from the location state
  const username = location.state?.username || '';

  const handleSettingsMouseEnter = () => {
    setIsSettingsHovered(true);
  };

  const handleSettingsMouseLeave = () => {
    setIsSettingsHovered(false);
  };

  const handleHomeMouseEnter = () => {
    setIsHomeHovered(true);
  };

  const handleHomeMouseLeave = () => {
    setIsHomeHovered(false);
  };

  const handleLogoutMouseEnter = () => {      //called
    setIsLogoutHovered(true);
  };

  const handleLogoutMouseLeave = () => {        //called
    setIsLogoutHovered(false);
  };

  // const home = ()=>{
  //   navigate("/home");
  // }
  // const settings = ()=>{
  //     navigate("/settings");
  // }
  const backLogin = () => {      
    navigate("/")
  };

  return (
    <div  className = "sidebar"  >

         <div className="logo">
           <img src={logo} alt="logo" />
         </div> 
   
    <div className="buttons">

        <div > 
             <button type="button"  className="hovered-button" 
              onMouseEnter={handleHomeMouseEnter}
              onMouseLeave={handleHomeMouseLeave}
              onClick={() => navigate("/home",{state:{ username }})} // Navigate to the "/home" route
             >
              
               <img src={isHomeHovered ? Hbutton : Home} alt="Home" />
             </button>
             <div className={`hometext ${isHomeHovered ? 'hovered' : ''}`}>
                <span className="name" >Home</span>  
            </div>
        </div>

        <div className="set"> 

          <button type="button" className="setb"
            onMouseEnter={handleSettingsMouseEnter}
            onMouseLeave={handleSettingsMouseLeave} 
            onClick={() => navigate("/settings",{state:{ username }})} // Navigate to the "/settings" route
             >
            <img src={isSettingsHovered ? settingsss : Sbutton} alt="settings" />
          </button>
          <div className= {`settings ${isSettingsHovered ? 'hovered' : ''}`}>
               Settings
             </div>  
        </div>
    </div>

    <div className="lb"> 
            <hr/>
           <button type="button" className="logb"
           onMouseEnter={handleLogoutMouseEnter}  //called
           onMouseLeave={handleLogoutMouseLeave}  //called
           onClick={backLogin}
            >
             <img src={isLogoutHovered ? logoutb : logout}  alt="logout" />
           </button>
                <div className={`logout ${isLogoutHovered ? 'hovered' : ''}`}>
                 <span className="name">Logout</span>  
                </div>
        </div>    

        
    </div>
  );
}

export default Header;
