import React, { useState, useEffect } from 'react';
import './Style.css';
import expor from './icons/export.png';
import filter from './icons/Filter 3.png';
import SideSlidePanel from './SideSlidePanel.js';
import './SideSlidePanel.css';
import Profile from './icons/Profile.png';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

function Datepicker({ onSearch, onDataExport, DATA, onDateSelect ,handleFilterChange, changedData}) {
  const [isValid, setIsValid] = useState(true);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [apiData, setApiData] = useState([]); // State to store API response

  const location = useLocation();
  const username = location.state?.username || '';

  // Use the changedData as needed in your Datepicker component
  console.log(changedData, 'changedData in Datepicker');

    useEffect(() => {
    // Fetch data when fromDate and toDate are available
    if (fromDate && toDate) {
      const fetchData = async () => {
        try {
          const response = await axios.post(
            'https://varapi.vthinkglobal.com/api/report',
            {
              fromDate,
              toDate,
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
          setApiData(response.data.report); // Store API response in state
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData(); // Call fetchData when fromDate and toDate are available
    }
    // Log the selected dates in the console
    console.log('From Date:', fromDate, 'To Date:', toDate);
  }, [fromDate, toDate]);
  
  useEffect(() => {
    // Set default values for fromDate and toDate when component mounts
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    const formatDateString = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const defaultFromDate = formatDateString(firstDayOfMonth);
    const defaultToDate = formatDateString(currentDate);

    setFromDate(defaultFromDate);
    setToDate(defaultToDate);

    // Assuming onDateSelect is a function to handle date selection
    // You can replace this with your actual function
    onDateSelect(defaultFromDate, defaultToDate);
  }, []);
  

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
    setIsValid(event.target.value <= toDate);

      // Invoke the callback with the selected dates
      onDateSelect(event.target.value, toDate);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
    setIsValid(fromDate <= event.target.value);

    // Invoke the callback with the selected dates
    onDateSelect(fromDate, event.target.value);

  };

  const handleSearchChange = (e) => {
    const newSearchQuery = e.target.value;
    onSearch(newSearchQuery); // Pass the search query to the parent component
  };

  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const openPanel = () => {
    setIsPanelOpen(true);
  };

  const closePanel = () => {
    setIsPanelOpen(false);
  };

  const JSONToCSV = (data) => {
    const headers = Object.keys(data[0]);
    const csvContent =
      'data:text/csv;charset=utf-8,' +
      headers.join(',') +
      '\n' +
      data.map((row) => headers.map((header) => row[header]).join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'Employee-data.csv');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className='table-container'>
      <div className='container'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '50px',
          }}
        >
          <h2
            style={{
              color: '#1DA1F2',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            Attendence Report
          </h2>{' '}
          <div style={{ display: 'flex', alignItems: 'center' , paddingRight:"20px"}}>
            <img
              src={Profile}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingRight: '10px',
              }}
            />
            <span>{username.toUpperCase()}</span>
          </div>
        </div>
        <hr />
        <div className='c1'>
          <div className='search'>
            <input
              type='text'
              placeholder='Search Name'
              className='wider-search-bar'
              onChange={handleSearchChange}
            />
          </div>
          <div className='datepicker'>
            <input
              type='date'
              className='datepicker-body'
              value={fromDate}
              onChange={handleFromDateChange}
            />
            <input
              type='date'
              className='datepicker-body'
              value={toDate}
              onChange={handleToDateChange}
            />
            <p className='valid' style={{ color: isValid ? 'black' : 'red' }}>
              {isValid ? '' : 'Invalid Date.'}
            </p>
          </div>
          <div className='filter'>
            <div className='Datepicker'>
              <button className='custom-button' onClick={openPanel}>
                <img src={filter} className='icon' />
              </button>
              <SideSlidePanel isOpen={isPanelOpen} onClose={closePanel} handleFilterChange={handleFilterChange} />
            </div>
            <button
              className='custom-button'
              onClick={() => changedData && JSONToCSV(changedData)}
            >
              <img src={expor} className='icon' />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Datepicker;
