import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter  as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import AppRouter from './AppRouter';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: '264d0ea6-fba2-4d90-aa74-bceeee062c44',
    authority: 'https://login.microsoftonline.com/3bc90aa9-088f-4447-9eb2-ff13839e19dd',
    redirectUri: 'https://var.vthinkdeveloper.com/'
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

// Use createRoot to render your app
const root = document.getElementById('root');
const rootElement = (
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <MsalProvider instance={new PublicClientApplication(msalConfig)}>
          <AppRouter />
        </MsalProvider>
      </Router>
    </Provider>
  </React.StrictMode>
);

const rootContainer = ReactDOM.createRoot(root);
rootContainer.render(rootElement);
