import React, { useState, useMemo,useEffect } from 'react';
import './mini.css'; 
import data from './Data/History-data.json';
import axios from 'axios';
import correct from "./icons/correct.png"
import wrong from "./icons/wrong.png";
import Pagination from './Pagination';

let PageSize = 5;

function History() {
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchedData, setFetchedData] = useState([]);
  const formatDate = (timestamp) => {
    const options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZone:'UTC'
    };
  
    const formattedDate = new Date(timestamp).toLocaleString('en-US', options);
    return formattedDate;
  };
  useEffect(() => {
    console.log('Fetching data from the API...');
    axios.get('https://varapi.vthinkglobal.com/api/upload_history')
      .then((response) => {
        console.log('API Response:', response.data);
        setFetchedData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);


  const replaceValueWithIndicator = (value) => {
    if (value === true) {
      return <img src={correct} alt="correct" />; // Green tick
    } else if (value === false) {
      return <img src={wrong} alt="false" />; // Red wrong
    }
    return value;
  };
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return fetchedData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage,fetchedData]);
  return (
    <div className="history">
         <p className="txt-history">History</p>
        <table>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Created Date</th>
                    <th>Uploaded Date</th>
                    <th>File Name</th>
                    <th>Employee</th>
                    <th>File Size</th>
                    <th>Status</th>
                </tr>
            </thead>
              <tbody>
              {currentTableData.map(item => {
            return (
              <tr key={item.id}>
              <td>{item.id}</td>
              <td>{formatDate(item.createdAt)}</td>
              <td>{formatDate(item.updatedAt)}</td>
              <td>{item.fileName}</td>
              <td>AJ Anand Robertson</td>
              <td>{item.fileSize}</td>
              <td>{replaceValueWithIndicator(item.isSuccessfullyUploaded)}</td>
              </tr>
                );
              })}
            </tbody>
        </table>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={fetchedData.length}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
    </div>
  )
}


export default History